import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Visual = memo(function Visual({
  description,
  image,
  title,
}: Props) {
  return (
    <Container row tag="section" stretch wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide>{image ? <Image {...image} /> : null}</RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-bottom: 15rem;

  @media (max-width: 1023px) {
    margin-bottom: 7.5rem;
  }
`

const LeftSide = styled.div`
  width: 43.3%;
  padding: 8.125rem 11.25vw 8.125rem 5.833vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.75rem 6.039vw 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 4rem;
  padding-left: 3rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 0.75rem;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
    padding-left: 2.375rem;
    &:before {
      width: 0.625rem;
    }
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.875rem;
`

const RightSide = styled.div`
  width: 56.7%;
  position: relative;

  img {
    width: calc(100% - 5.833vw);
    height: 100%;
    border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
    object-fit: cover;
    position: absolute;
    top: 6.125rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 60%;
    transform: translateY(3.75rem);
    img {
      width: calc(100% - 12.078vw);
      top: 0;
      left: 6.039vw;
    }
  }
`
